import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  phoneNumberGeneral = environment.phoneNumberGeneral;
  emailContactGeneral = environment.emailContactGeneral;
  emailContactRole1 = environment.emailContactRole1;
  emailContactRole2 = environment.emailContactRole2;

  constructor() {
    this.phoneNumberGeneral = environment.phoneNumberGeneral;
    this.emailContactGeneral = environment.emailContactGeneral;
    this.emailContactRole1 = environment.emailContactRole1;
    this.emailContactRole2 = environment.emailContactRole2;
  }

  ngOnInit() {
  }

}
