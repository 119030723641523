import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer-small',
  templateUrl: './footer-small.component.html',
  styleUrls: ['./footer-small.component.css']
})
export class FooterSmallComponent implements OnInit {
  systemName: string;
  year: number = new Date().getFullYear();
  constructor() {
    this.systemName = environment.systemName;
  }

  ngOnInit() {
  }

}
