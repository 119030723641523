import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'swiftscooter-frontoffice';

  public language: string;
  languages: any;

  constructor(
    private translate: TranslateService
  ) {
    this.languages = environment.languages;

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(environment.defaultLanguage);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let lang = localStorage.getItem('currentLang');

    if (lang != 'null' && lang != null) {
      translate.use(lang);
    } else {
      translate.use(environment.defaultLanguage);
      localStorage.setItem('currentLang', environment.defaultLanguage);
    }

    this.language = localStorage.getItem('currentLang');
    if (this.language == 'null') {
      this.language = environment.defaultLanguage;
    }
  }

  ngOnInit() {
  }

}
