import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import {environment} from '../../../environments/environment';
import * as AOS from 'aos';

@Component({
  selector: 'app-how-to',
  templateUrl: './how-to.component.html',
  styleUrls: ['./how-to.component.css']
})
export class HowToComponent implements OnInit, AfterViewInit {
  @ViewChild('content', { static: false }) content: ElementRef;
  showModal = false;
  linkSelector: string;
  appStoreLink = environment.appStoreLink;
  googlePlayLink = environment.googlePlayLink;

  constructor(
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
  ) { }
  ngAfterViewInit() {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      if (this.deviceService.os.toLowerCase().includes('android')) {
        this.linkSelector = 'android';
      } else if (this.deviceService.os.toLowerCase().includes('ios')) {
        this.linkSelector = 'ios';
      }
    }
  }
  ngOnInit() {
    AOS.init();
  }
  openAppStore() {
    if (this.deviceService.os.toLowerCase().includes('android')) {
      window.open('market://details?id=' + environment.googlePlayLink.split('=')[1]);
    } else if (this.deviceService.os.toLowerCase().includes('ios')) {
      window.open('itms-apps://itunes.apple.com/app/' + environment.appStoreLink.split('app/')[1]);
    }
  }
  openDialog() {
    this.showModal = true;
    this.modalService.open(this.content, {
      centered: true,
    });
  }
  close() {
    this.showModal = false;
  }
}
