import { Component, OnInit } from '@angular/core';
import google_map_style from '../../../assets/google_map_style.json';
declare const google: any;
import { environment } from '../../../environments/environment';
import { MapRestriction } from '@agm/core/services/google-maps-types';
import * as AOS from 'aos';

@Component({
  selector: 'app-where-we-are',
  templateUrl: './where-we-are.component.html',
  styleUrls: ['./where-we-are.component.css']
})
export class WhereWeAreComponent implements OnInit {
  googleMapStyle = google_map_style;
  latitude = environment.map.location.lat;
  longitude = environment.map.location.lon;
  zoom = environment.map.location.zoom;
  restriction: MapRestriction;
  markers = [
    {
      lat: 37.117305,
      lng: -8.535873,
    },
    {
      lat: 40.151765,
      lng: -8.857779,
    },
    {
      lat: 39.336670,
      lng: -8.936239,
    },
  ];

  private icon = {
    url: ('../../assets/img/where/map_pin.png'),
    scaledSize: {
      height: 77,
      width: 65,
    }
  };

  constructor() {
    this.restriction = { latLngBounds: { north: 50, east: 30, south: 30, west: -40 } };
  }

  ngOnInit() {
    AOS.init();
  }

}
