declare var $: any;
import { environment } from '../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import 'src/assets/js/main';
import google_map_style from '../../../assets/google_map_style.json';
import { MapRestriction } from '@agm/core/services/google-maps-types';
declare const google: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  googleMapStyle = google_map_style;
  latitude = environment.map.location.lat;
  longitude = environment.map.location.lon;
  zoom = environment.map.location.zoom;
  appStoreLink = environment.appStoreLink;
  googlePlayLink = environment.googlePlayLink;
  systemName = environment.systemName;
  public languages: any = environment.languages;
  public language: string;
  restriction: MapRestriction;
  markers = [
    {
      lat: 37.117305,
      lng: -8.535873,
    },
    {
      lat: 40.151765,
      lng: -8.857779,
    },
    {
      lat: 39.336670,
      lng: -8.936239,
    },
  ];

  private icon = {
    url: ('../../assets/img/where/map_pin.png'),
    scaledSize: {
      height: 77,
      width: 65,
    }
  };

  ngOnInit() {
    AOS.init();
    $('.js-fullheight').css('height', $(window).height());
  }

  constructor(
    private translate: TranslateService
  ) {

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(environment.defaultLanguage);
    this.restriction = { latLngBounds: { north: 50, east: 30, south: 30, west: -40 } };

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const lang = localStorage.getItem('currentLang');

    if (lang != 'null' && lang != null) {
      translate.use(lang);
    } else {
      translate.use(environment.defaultLanguage);
      localStorage.setItem('currentLang', environment.defaultLanguage);
    }

    this.language = localStorage.getItem('currentLang');
    if (this.language == 'null') {
      this.language = environment.defaultLanguage;
    }
  }
  switchLanguage(language: string) {
    this.translate.use(language);
    this.language = language;
    localStorage.setItem('currentLang', this.language);
    location.reload();
  }
}
