import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
declare var $: any;
import * as AOS from 'aos';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, AfterViewInit {
  @ViewChild('content', { static: false }) content: ElementRef;
  linkSelector: string;
  appStoreLink = environment.appStoreLink;
  googlePlayLink = environment.googlePlayLink;
  instagramLink = environment.instagramLink;
  facebookLink = environment.facebookLink;
  public language: string;
  languages: any;
  showModal = false;

  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
  ) {
    this.facebookLink = environment.facebookLink;
    this.instagramLink = environment.instagramLink;
    this.languages = environment.languages;
    translate.setDefaultLang(environment.defaultLanguage);
    const lang = localStorage.getItem('currentLang');
    if (lang != 'null' && lang != null) {
      translate.use(lang);
    } else {
      translate.use(environment.defaultLanguage);
      localStorage.setItem('currentLang', environment.defaultLanguage);
    }
    this.language = localStorage.getItem('currentLang');
    if (this.language == 'null') {
      this.language = environment.defaultLanguage;
    }
  }
  ngAfterViewInit() {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      if (this.deviceService.os.toLowerCase().includes('android')) {
        this.linkSelector = 'android';
      } else if (this.deviceService.os.toLowerCase().includes('ios')) {
        this.linkSelector = 'ios';
      }
    }
  }
  ngOnInit() {
    this.clearLockScroller();
    this.lockScroller();
    this.animateButton();
    this.clearAll();
    AOS.init();
  }
  animateButton() {
    document.querySelectorAll('.menu-animation').forEach(btn => {
      btn.addEventListener('click', e => {
        btn.classList.toggle('active');
        if ($('.menu-animation').hasClass('active')) {
          $('#myNav').addClass('activated');
          $('#blur-menu-overlay').addClass('activated-overlay');
        } else {
          $('#myNav').removeClass('activated');
          $('#blur-menu-overlay').removeClass('activated-overlay');
        }
        });
      });
  }
  clearLockScroller() {
    if ($('body').hasClass('lock-scroll')) {
      $('body').removeClass('lock-scroll');
    }
  }
  lockScroller() {
      $('.menu-animation').on('click', (event) => {
        if ($('.menu-animation').hasClass('active')) {
          lockScroll(false);
        } else {
          lockScroll(true);
        }
      });
      function lockScroll(shouldLock: boolean) {
        if (shouldLock === undefined) {
          shouldLock = true;
        }
        if (shouldLock) {
          if (!$('body').hasClass('lock-scroll')) {
            $('body').addClass('lock-scroll');
          }
        } else {
          if ($('body').hasClass('lock-scroll')) {
            $('body').removeClass('lock-scroll');
          }
        }
      }
  }
  clearAll() {
    $('.overlay-left-menu').on('click', (event) => {
      $('#myNav').removeClass('activated');
      $('.menu-animation').removeClass('active');
      $('#blur-menu-overlay').removeClass('activated-overlay');
      if ($('body').hasClass('lock-scroll')) {
        $('body').removeClass('lock-scroll');
      }
    });
  }
  openDialog() {
    this.showModal = true;
    this.modalService.open(this.content, {
      centered: true,
    });
  }
  close() {
    this.showModal = false;
  }
  openAppStore() {
    if (this.deviceService.os.toLowerCase().includes('android')) {
      window.open('market://details?id=' + environment.googlePlayLink.split('=')[1]);
    } else if (this.deviceService.os.toLowerCase().includes('ios')) {
      window.open('itms-apps://itunes.apple.com/app/' + environment.appStoreLink.split('app/')[1]);
    }
  }
  switchLanguage(language: string) {
    this.translate.use(language);
    this.language = language;
    localStorage.setItem('currentLang', this.language);
    location.reload();
}
}
