import { Component, OnInit } from '@angular/core';
declare var $: any;
import 'src/assets/js/back-top';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.css']
})
export class BackToTopComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
