import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  systemName: string;
  year: number = new Date().getFullYear();
  instagramLink = environment.instagramLink;
  facebookLink = environment.facebookLink;
  appStoreLink = environment.appStoreLink;
  googlePlayLink = environment.googlePlayLink;
  public language: string;
  languages: any;

  constructor(
    private translate: TranslateService
  ) {
    this.languages = environment.languages;
    this.systemName = environment.systemName;
    this.appStoreLink = environment.appStoreLink;
    this.googlePlayLink = environment.googlePlayLink;
    this.facebookLink = environment.facebookLink;
    this.instagramLink = environment.instagramLink;

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(environment.defaultLanguage);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let lang = localStorage.getItem('currentLang');

    if (lang != 'null' && lang != null) {
      translate.use(lang);
    } else {
      translate.use(environment.defaultLanguage);
      localStorage.setItem('currentLang', environment.defaultLanguage);
    }

    this.language = localStorage.getItem('currentLang');
    if (this.language == 'null') {
      this.language = environment.defaultLanguage;
    }
  }

  ngOnInit() {
  }
  switchLanguage(language: string) {
    this.translate.use(language);
    this.language = language;
    localStorage.setItem('currentLang', this.language);
    location.reload();
  }
}
