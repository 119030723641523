import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';
import { AgmDrawingModule } from '@agm/drawing';
import { TermsOfUseComponent } from './Pages/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './Pages/privacy-policy/privacy-policy.component';
import { UserAgreementComponent } from './Pages/user-agreement/user-agreement.component';
import { HomepageComponent } from './Pages/homepage/homepage.component';
import { FooterSmallComponent } from './Pages/Components/footer-small/footer-small.component';
import { MenuComponent } from './Pages/Components/menu/menu.component';
import { WhereWeAreComponent } from './Pages/where-we-are/where-we-are.component';
import { HowToComponent } from './Pages/how-to/how-to.component';
import { AboutUsComponent } from './Pages/about-us/about-us.component';
import { ContactsComponent } from './Pages/contacts/contacts.component';
import { JobsComponent } from './Pages/jobs/jobs.component';
import { FooterComponent } from './Pages/Components/footer/footer.component';
import { LoadingScreenComponent } from './Pages/Components/loading-screen/loading-screen.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { BackToTopComponent } from './Pages/Components/back-to-top/back-to-top.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'contact', component: ContactsComponent },
  { path: 'careers', component: JobsComponent },
  { path: 'how-to-use', component: HowToComponent },
  { path: 'where-we-are', component: WhereWeAreComponent },
  { path: 'user-agreement', component: UserAgreementComponent },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    UserAgreementComponent,
    HomepageComponent,
    FooterSmallComponent,
    MenuComponent,
    WhereWeAreComponent,
    HowToComponent,
    AboutUsComponent,
    ContactsComponent,
    JobsComponent,
    FooterComponent,
    LoadingScreenComponent,
    BackToTopComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AgmDrawingModule,
    DeviceDetectorModule.forRoot(),
    NgbModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDL6WJAprQsZH2uNjno4Zz-R3Ivgd5B7bc',
      libraries: ['places', 'drawing', 'geometry']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
