import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  emailContactGeneral = environment.emailContactGeneral;
  constructor() {
    this.emailContactGeneral = environment.emailContactGeneral;
  }

  ngOnInit() {
  }

}
