// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultLanguage: 'pt-pt',
  systemName: 'Swift Scooter',
  languages: [
    { 'code': 'en-us', 'description': 'EN' },
    { 'code': 'pt-pt', 'description': 'PT' },
    { 'code': 'es-es', 'description': 'ES' },
    { 'code': 'fr-fr', 'description': 'FR' },
  ],
  map: {
    active: false,
    location: { lat: 39.917231, lon: -7.946849, zoom: 6 },
  },
  phoneNumberGeneral: '+351 308 800 646',
  emailContactGeneral: 'support@swiftscooter.com',
  emailContactRole1: 'email1@swiftscooter.com',
  emailContactRole2: 'email2@swiftscooter.com',
  appStoreLink: 'https://apps.apple.com/us/app/id1524798425',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=com.wgs.scooter',
  facebookLink: 'https://www.facebook.com/swiftscooter/',
  instagramLink: 'https://www.instagram.com/swiftscooter/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
