import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  appStoreLink = environment.appStoreLink;
  googlePlayLink = environment.googlePlayLink;
  constructor() {
    this.appStoreLink = environment.appStoreLink;
    this.googlePlayLink = environment.googlePlayLink;
  }

  ngOnInit() {
    AOS.init();
  }

}
